<template lang="pug">
.d-flex( 
  :class="{ 'bg-gray-100' : view.status !== 'selected' }"
)
  div.text-center.flex-shrink-0.text-sm.border-r.pa-2.px-4.align-center
    div {{ $day(notification.createdAt).format('MMM Do') }}
    div.text-gray-400 {{ $day(notification.createdAt).format('hh:mm a') }}
  div.flex-grow-1.px-4.pa-2.relative
    .absolute.right-0
      v-chip.rounded-lg(
        style="top: -22px;" 
        color="primary" 
        x-small 
        v-if="view.status === 'new'" 
      ) New  
    .d-flex.align-start
      v-icon.ml-2.mr-6.shrink-1.pt-1(
        :color="notification.statusContext.color.icon"
      ) {{ notification.statusContext.icon }}

      .d-flex.w-full.align-center(
        @click="goToExpense(view.status, view.notification.to, view.id)" 
      )
        .flex.flex-column.flex-grow-1
          h5( :class="['text-' + notification.statusContext.color.icon + '-700']" ) {{ notification.title }}
          .mb-2.text-sm.text-gray-500( v-html="subTitleFormatted(notification.subTitle)" )
        v-btn.flex-shrink-1(
          v-if="view.status === 'selected'"
          @click.prevent.stop="review(view.id)"
          color="grey"
          outlined 
          fab
          x-small 
          text 
        ) 
          v-icon mdi-close
  div.align-self-center
    v-badge.mr-6(
      style="padding-bottom: 1px"
      v-if="['viewed', 'new'].includes(view.status)"
      dot
      color="red"
    )
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  name: 'NotificationCard',

  props: ['item'],

  setup(props, { emit, root: { $FeathersVuex, $store, $router, $snackSuccess } }) {
    const { NotificationView } = $FeathersVuex.api;

    const view = ref(props.item);
    const notification = ref(props.item.notification)
    const subTitleFormatted = (subTitle) => {
      return subTitle.split('|').join(' &#183; ');
    };
    const goToExpense = (status, to, id) => {
      if (status !== 'dismissed') {
        $store.dispatch('notification-views/patch', [id, { status: 'selected' }]);
      }
      $router.push(to)
    }
    const review = async (id) => {
      const notificationView = new NotificationView({ 
        id, 
        status: 'dismissed' 
      });

      await notificationView.patch();
      $snackSuccess('Notification dismissed')
      emit('refresh')
    }

    return {
      view,
      notification,

      subTitleFormatted,
      goToExpense,
      review
    }
  }
}
</script>

<style>

</style>